import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CardItem from "../components/carditem"


import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import AniLink from "gatsby-plugin-transition-link/AniLink"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: "100%",
    paddingTop: 0, // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardbg: props => ({
    backgroundColor: props.backgroundColor,
  }),
}))

export default function Component(props) {
  console.log("shows", props)
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  var nodes = props.data.allDirectusPodcast.edges
  //<Badge badgeContent={node.episodes.length} color="error">
  //</Badge>
  return (
    <div>
      <SEO title="Shows" />

      <Breadcrumbs aria-label="breadcrumb">
        <AniLink cover direction="left" to="/" variant="body2">
          Home
        </AniLink>
        <Typography color="textPrimary">Shows</Typography>
      </Breadcrumbs>

      <Container component="main" maxWidth="xs">
        <div className="columns is-mobile">
            {nodes.map((nodeItem, index) => {
              var node = nodeItem.node
              console.log("nodeitem", nodeItem, index)
              return (
                <div className="column is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd" key={index}>
                  <CardItem
                    url={node.slug}
                    backgroundColor={node.color}
                    img={(node.image != undefined) ? node.image.localFile.childImageSharp.fluid.src : ""}
                    title={node.title}
                    subtitle={node.subtitle}
                    count={node.episodes.length}
                  ></CardItem>
                </div>
              )
            })}
          </div>
      </Container>
    </div>
  )
}

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.

export const query = graphql`
  query MyQuery {
    allDirectusPodcast {
      edges {
        node {
          title
          subtitle
          url
          slug
          color
          episodes {
            id
          }
          image {
            localFile {
              url
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 250) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
